import { all, call, put, select } from 'redux-saga/effects';

import { getData, postData } from '../../services/API/axios';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';

import {
  getPatientRoundHistory,
  getEmployeeRoundHistory
} from '../actions/Report.action';
import { clearRoundState } from '../actions/Round.action';
import {
  getTaskByAccountNumber,
  getTasksByEmployeeId
} from '../actions/Task.action';
import { clearRoundSurvey } from '../actions/Template.action';
import { getPatientsToRound } from '../actions/User.action';

import { postNewTask } from './Task.saga';
import {
  getEmployeesToRound,
  getCSRNSubjectsToRoundForList,
  getCNEdSubjectsToRoundForList,
  getValidationSubjectsToRoundForList
} from '../actions/Employee.action';

// Round sagas

export function* postRound({ data }) {
  const { type } = data;

  // Select round data from the store
  const { currentRound, isRoundStarted, pendingFeedbacks, pendingIssues } =
    yield select(state => state.RoundReducer);
  const { facilityId } = yield select(
    state => state.AuthorizedUser.authorizedUser
  );

  // If there is no round data, return
  if (!Object.keys(currentRound).length > 0) return;

  const url = `${API.postRoundUrl}`;
  const multiShare = pendingIssues.length + pendingFeedbacks.length > 1;

  try {
    // if Issues attached to a round post it to /task
    if (
      (pendingIssues.length > 0 || pendingFeedbacks.length > 0) &&
      isRoundStarted
    ) {
      yield all([
        ...pendingFeedbacks.map(fb =>
          call(postNewTask, {
            data: {
              ...fb,
              roundId: currentRound.roundId,
              multiShare
            }
          })
        ),
        ...pendingIssues.map(issue =>
          call(postNewTask, {
            data: {
              ...issue,
              roundId: currentRound.roundId,
              multiShare
            }
          })
        )
      ]);
      if (multiShare) {
        yield put({
          type: ACTIONS.TASK.CLEAR_SHARING_Q
        });
      }
      toast('All round tasks posted!', 'success', 1000, 500);
      // add the taskIds to the round
      currentRound.taskIds = [
        ...currentRound.taskIds,
        ...pendingIssues.map(issue => issue.taskId),
        ...pendingFeedbacks.map(fb => fb.taskId)
      ];
    }
    // Call the POST /round endpoint with the round payload
    const { status } = yield call(postData, url, currentRound);
    // If successful remove loading flags from the store
    if (status === 200) {
      const { roundingType } = currentRound;

      // Update store with new round info
      if (type === 'patient') {
        // TODO: Is this necessary?
        yield put(
          getPatientsToRound({
            facilityId
          })
        );
        yield put(
          getPatientRoundHistory({
            facilityId,
            patientId: currentRound.patientId || currentRound.accountNum
          })
        );
        // if issues attached
        if (
          (pendingIssues.length > 0 || pendingFeedbacks.length > 0) &&
          isRoundStarted &&
          currentRound.accountNum
        ) {
          yield put(
            getTaskByAccountNumber({
              accountNumber: currentRound.accountNum,
              facilityMnemonic: facilityId
            })
          );
        }
      } else if (type === 'employee') {
        // TODO: Is this necessary?
        yield put(getEmployeesToRound({ hcaid: currentRound.userId }));
        yield put(
          getEmployeeRoundHistory({
            employeeId: currentRound.employeeId
          })
        );
        if (
          (pendingIssues.length > 0 || pendingFeedbacks.length > 0) &&
          isRoundStarted
        ) {
          yield put(
            getTasksByEmployeeId({
              employeeId: currentRound.employeeId,
              roundingTypes: ['employee', 'cned']
            })
          );
        }
      } else if (type === 'validation') {
        // TODO: Is this necessary?
        yield put(
          getValidationSubjectsToRoundForList({ hcaid: currentRound.userId })
        );
        yield put(
          getEmployeeRoundHistory({
            employeeId: currentRound.employeeId,
            roundingTypes: ['validation']
          })
        );
      } else {
        // TODO: Is this necessary?
        if (roundingType === 'csrn') {
          yield put(
            getCSRNSubjectsToRoundForList({
              hcaid: currentRound.userId
            })
          );
        } else {
          yield put(
            getCNEdSubjectsToRoundForList({
              hcaid: currentRound.userId
            })
          );
        }
        yield put(
          getEmployeeRoundHistory({
            employeeId: currentRound.employeeId,
            roundingTypes:
              roundingType === 'csrn' ? 'csrn' : ['employee', 'cned']
          })
        );
        if (
          roundingType === 'cned' &&
          isRoundStarted &&
          (pendingIssues.length > 0 || pendingFeedbacks.length > 0)
        ) {
          yield put(
            getTasksByEmployeeId({
              employeeId: currentRound.employeeId,
              roundingTypes: ['employee', 'cned']
            })
          );
        }
      }
      yield put({
        type: ACTIONS.ROUND.COMPLETE_ROUND_SUCCESS
      });
    } else {
      toast('Round failed to Post!', 'error', null, 500, true);
      yield put({
        type: ACTIONS.ROUND.COMPLETE_ROUND_FAILURE
      });
    }
  } catch (error) {
    console.log(error);
    toast('Round failed to Post!', 'error', null, 500, true);
    yield put({
      type: ACTIONS.ROUND.COMPLETE_ROUND_FAILURE
    });
  }
}

export function* getRound(action) {
  const { roundId, fromHistorydrawer } = action.data;

  const accountNum = yield select(
    state => state.UserReducer.selectedPatient?.accountNum
  );

  yield put(clearRoundState());
  yield put(clearRoundSurvey());
  const url = `${API.getRoundUrl}?roundId=${roundId}`;

  try {
    const response = yield call(getData, url);

    if (response.status === 200) {
      if (
        accountNum &&
        accountNum.substring(0, 2) === 'UL' &&
        !fromHistorydrawer
      ) {
        yield put({
          type: ACTIONS.REPORT.GET_PATIENT_ROUND_HISTORY_SUCCESS,
          data: response.data
        });
        return yield put({
          type: ACTIONS.ROUND.GET_ROUND_SUCCESS,
          data: {}
        });
      }

      const templatesUrl = `${API.getTemplatesMasterUrl}?condensed=true&archived=false`;
      const { data: masterTemplateData } = yield call(getData, templatesUrl);

      const foundMasterTemplate = masterTemplateData.find(
        template => template.templateId === response.data[0].templateId
      );

      if (!foundMasterTemplate) {
        const templateByIdUrl = `${API.getTemplateById}?templateId=${response.data[0].templateId}`;
        try {
          const { data, status } = yield call(getData, templateByIdUrl);
          if (status === 200) {
            yield put({
              type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_SUCCESS,
              data
            });
          } else {
            yield put({
              type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_FAILURE
            });
          }
        } catch (err) {
          yield put({
            type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_FAILURE
          });
        }
        return yield put({
          type: ACTIONS.ROUND.GET_ROUND_SUCCESS,
          data: response.data[0]
        });
      }
      return yield put({
        type: ACTIONS.ROUND.GET_ROUND_SUCCESS,
        data: response.data[0]
      });
    }
    return yield put({
      type: ACTIONS.ROUND.GET_ROUND_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.ROUND.GET_ROUND_FAILURE
    });
  }
}
