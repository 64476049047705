/**
 * External Imports
 */
import { FC, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NeuContainer, NeuAvatar, NeuLabel } from '@neutron/react';
import { orderBy } from 'lodash';
import { format } from 'date-fns';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import Table from '../../shared/table';

import { RootState } from '../../../redux/store';
import { clearEmployeeRoundHistory } from '../../../redux/actions/Report.action';
import { clearTasksByEmployeeId } from '../../../redux/actions/Task.action';
import {
  getEmployeeNotes,
  setSelectedEmployee
} from '../../../redux/actions/User.action';

import { logRoundProfile } from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';
import {
  date,
  handleBlobPicUrl,
  userProfilePicUrl
} from '../../../utils/helpers';
import defaultImage from '../../../assets/images/defaultImage.png';

/**
 * Global Type Definition Imports
 */
import { Employee, RoundHistory } from '../../../config/interfaces';

/**
 * Style Imports
 */
import {
  StyledLabel,
  StyledTableHeading
} from '../../shared/table/Table.styles';
import {
  AvatarDiv,
  CompetencyImg,
  CompleteIcon,
  CompleteRow,
  EmployeeInfoDiv,
  EmployeeAvatar,
  IconDiv,
  LastValidationLabel,
  OverdueImg,
  ToDoIcon,
  ToDoLabel,
  ToDoRow
} from './ValidationListTable.styles';

/**
 * Static Asset Imports
 */
import overdueLogo from '../../../assets/images/alarm.png';
import competentIcon from '../../../assets/images/competent_icon.png';
import developmentIcon from '../../../assets/images/development_icon.png';
import expertIcon from '../../../assets/images/expert_icon.png';

interface ValidationListTableProps {
  validationFilter: string;
  validationRounds: (Employee & RoundHistory)[];
  searchTerm: string;
  sasToken: string;
  selectedDeptsFromStore: string[];
  validationCompetencies: string[];
}

const ValidationListTable: FC<ValidationListTableProps> = ({
  validationFilter,
  validationRounds,
  searchTerm,
  sasToken,
  selectedDeptsFromStore,
  validationCompetencies
}) => {
  const dispatch = useDispatch();

  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [sortKey, setSortKey] = useState<string>('endDueDate');
  const [active, setActive] = useState<string>('Last Round');

  const { logTrackingEvent } = useAnalyticsApi();

  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const navigate = useNavigate();

  const openEmployeeProfile = (selectedEmployee: Employee) => {
    dispatch(clearEmployeeRoundHistory());
    dispatch(clearTasksByEmployeeId());
    dispatch(setSelectedEmployee(selectedEmployee));
    dispatch(getEmployeeNotes(selectedEmployee.hcaid));
    logTrackingEvent(logRoundProfile('validation'));
    navigate('/validation/profile');
  };

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const filteredValidationRounds = useMemo(() => {
    const validationRoundsFilteredByDept = validationRounds.filter(employee => {
      const employeeDept =
        (employee.adHoc ? employee?.employeeDept : employee?.dept) || 'Unknown';
      return selectedDeptsFromStore.some(
        selectedDept => selectedDept === employeeDept
      );
    });

    const validationRoundSubjectsAndAdHoc =
      validationRoundsFilteredByDept.reduce(
        (
          acc: {
            validationSubjects: (Employee & RoundHistory)[];
            adHoc: (Employee & RoundHistory)[];
          },
          employee: Employee & RoundHistory
        ) =>
          employee.adHoc
            ? { ...acc, adHoc: [...acc.adHoc, employee] }
            : {
                ...acc,
                validationSubjects: [...acc.validationSubjects, employee]
              },
        { validationSubjects: [], adHoc: [] }
      );

    const validationSubjectsToRound =
      validationRoundSubjectsAndAdHoc.validationSubjects;
    const validationAdHocsToRound = validationRoundSubjectsAndAdHoc.adHoc;

    let employeesToShow;
    switch (validationFilter) {
      case 'completed':
        employeesToShow = [
          ...validationSubjectsToRound.filter(
            employee => employee?.complianceStatus === 'Completed'
          ),
          ...validationAdHocsToRound
        ];
        break;
      case 'to-do':
        employeesToShow = validationSubjectsToRound.filter(
          employee =>
            employee?.complianceStatus === 'Todo' ||
            employee?.complianceStatus === 'Overdue' ||
            !employee.complianceStatus
        );
        break;
      case 'expert':
        employeesToShow = validationSubjectsToRound.filter(employee =>
          employee.lastRound?.tag.includes('Validation_NLR_E')
        );
        break;
      case 'competent':
        employeesToShow = validationSubjectsToRound.filter(employee =>
          employee.lastRound?.tag.includes('Validation_NLR_C')
        );
        break;
      case 'developing':
        employeesToShow = validationSubjectsToRound.filter(employee =>
          employee.lastRound?.tag.includes('Validation_NLR_D')
        );
        break;
      default:
        employeesToShow = validationSubjectsToRound;
    }

    const q = new RegExp(dbSearchTerm.trim().replace(/\\/g, ''), 'i');
    return employeesToShow.filter(
      employee =>
        `${employee.firstName} ${employee.lastName}`.match(q) ||
        employee.hcaid.match(q) ||
        `${employee.lastName} ${employee.firstName}`.match(q)
    );
  }, [
    validationFilter,
    validationRounds,
    dbSearchTerm,
    selectedDeptsFromStore
  ]);

  const sortedValidationRounds = useMemo(() => {
    const filteredValidationRoundsCopy = [...filteredValidationRounds];
    const orderBySortDir = sortDir === 'asc' ? 'asc' : 'desc';
    return filteredValidationRoundsCopy.length > 0
      ? orderBy(
          filteredValidationRoundsCopy,
          [round => (round as any)[sortKey]?.toLowerCase() || ''],
          [orderBySortDir]
        )
      : filteredValidationRoundsCopy;
  }, [filteredValidationRounds, sortDir, sortKey]);

  const isTableEmpty = useMemo(
    () => !dbSearchTerm && sortedValidationRounds.length === 0,
    [dbSearchTerm, sortedValidationRounds]
  );

  const isTableSearchEmpty = useMemo(
    () => dbSearchTerm && sortedValidationRounds.length === 0,
    [dbSearchTerm, sortedValidationRounds]
  );

  const sortedToDoValidationRounds = useMemo(() => {
    return sortedValidationRounds.filter(
      employee =>
        employee.complianceStatus === 'Todo' ||
        employee.complianceStatus === 'Overdue' ||
        !employee.complianceStatus
    );
  }, [sortedValidationRounds]);

  const sortedCompleteValidationRounds = useMemo(() => {
    return sortedValidationRounds.filter(
      employee => employee.complianceStatus === 'Completed'
    );
  }, [sortedValidationRounds]);

  const cols =
    '{ "Employee":"22%","Department":"21%","Rounded By":"21%","Last Round":"19%","Round Due":"17%" }';

  const headers: JSX.Element[] = [
    <StyledTableHeading
      id="Validation-Table-Employee-Column"
      key="Employee"
      slot="Employee"
      icon={
        !(active === 'Employee') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('fullName', 'Employee')}
      active={active === 'Employee'}
    >
      <span className="text-clamp text-clamp__1 break-all">Employee</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="Validation-Table-Department-Column"
      key="Department"
      slot="Department"
      icon={
        !(active === 'Department') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('dept', 'Department')}
      active={active === 'Department'}
    >
      <span className="text-clamp text-clamp__1 break-all">Department</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="Validation-Table-Rounded-By-Column"
      key="Rounded By"
      slot="Rounded By"
      icon={
        !(active === 'Rounded By') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastRoundedBy', 'Rounded By')}
      active={active === 'Rounded By'}
    >
      <span className="text-clamp text-clamp__1 break-all">Validated By</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="Validation-Table-Last-Round-Column"
      data-testid="Validation-Table-Last-Round-Column-Heading"
      key="Last Round"
      slot="Last Round"
      icon={
        !(active === 'Last Round') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastRoundDate', 'Last Round')}
      active={active === 'Last Round'}
    >
      <span className="text-clamp text-clamp__1 break-all">Last Validated</span>
    </StyledTableHeading>,
    <StyledTableHeading
      id="Validation-Table-Round-Due-Column"
      key="Round Due"
      slot="Round Due"
      icon={
        !(active === 'Round Due') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('endDueDate', 'Round Due')}
      active={active === 'Round Due'}
    >
      Validation Due
    </StyledTableHeading>
  ];

  const formattedEmployeeRow = (
    employee: Employee & RoundHistory,
    type: 'Completed' | 'To Do'
  ) => {
    const competencyIcon = employee.lastRound?.tag.includes('Validation_NLR_E')
      ? expertIcon
      : employee.lastRound?.tag.includes('Validation_NLR_C')
      ? competentIcon
      : employee.lastRound?.tag.includes('Validation_NLR_D')
      ? developmentIcon
      : '';

    return (
      <>
        <EmployeeInfoDiv className="h-100 px-0" slot="Employee">
          <IconDiv>
            {type === 'Completed' ? (
              <CompleteIcon color="blue-80">check</CompleteIcon>
            ) : employee.complianceStatus === 'Overdue' ? (
              <OverdueImg width="20px" alt="overdue logo" src={overdueLogo} />
            ) : (
              <ToDoIcon color="orange-50">circle</ToDoIcon>
            )}
          </IconDiv>
          <AvatarDiv>
            <NeuAvatar
              color="gray-60"
              style={{ height: '40px', width: '40px' }}
            >
              {sasToken ? (
                <EmployeeAvatar
                  src={
                    handleBlobPicUrl(employee.pictureUrl, sasToken) ??
                    userProfilePicUrl(employee.hcaid, sasToken)
                  }
                  alt="Employee Image"
                  onError={(e: any) => {
                    e.currentTarget.src = defaultImage;
                    return null;
                  }}
                />
              ) : (
                <EmployeeAvatar src={defaultImage} alt="Default Image" />
              )}
            </NeuAvatar>
          </AvatarDiv>
          <NeuContainer>
            <ToDoLabel color="primary-70">
              {employee.firstName} {employee.lastName}
            </ToDoLabel>
            <StyledLabel>{employee.hcaid}</StyledLabel>
            <StyledLabel className="text-clamp text-clamp__1">
              {employee.title}
            </StyledLabel>
          </NeuContainer>
        </EmployeeInfoDiv>
        <NeuLabel color="gray-90" slot="Department">
          {employee.adHoc ? employee.employeeDept : employee.dept}
        </NeuLabel>
        <EmployeeInfoDiv className="h-100 px-0" slot="Rounded By">
          <NeuContainer className="px-0">
            <NeuLabel>
              {employee.lastRoundDate &&
              employee.lastRoundedBy &&
              employee.lastRoundedBy !== 'Unknown'
                ? employee.lastRoundedBy
                : employee.lastRoundDate &&
                  employee.lastRoundedBy &&
                  employee.lastRoundedBy === 'Unknown'
                ? 'Unknown'
                : ''}
            </NeuLabel>
            <StyledLabel>{employee.lastRoundedById}</StyledLabel>
          </NeuContainer>
        </EmployeeInfoDiv>
        <NeuContainer className="flex-row pl-0" slot="Last Round">
          <LastValidationLabel>
            {employee.lastRoundedBy &&
            employee.lastRoundedBy !== 'Unknown' &&
            (!employee.lastRoundDate ||
              (employee.lastRoundDate &&
                !date.isOrbitTimestamp(employee.lastRoundDate)))
              ? 'Unknown'
              : employee.lastRoundedBy && employee.lastRoundDate
              ? format(date.parseDate(employee.lastRoundDate), 'MM/dd/yyyy') // make unknown if name and no date
              : ''}
          </LastValidationLabel>
          {validationCompetencies.includes(employee.lastRound?.tag) && (
            <CompetencyImg
              width="24"
              alt="competency logo"
              src={competencyIcon}
            />
          )}
        </NeuContainer>
        <NeuLabel slot="Round Due">
          {date.datePrettyString(employee.endDueDate)}
        </NeuLabel>
      </>
    );
  };

  const formatRows = (
    employeeRounds: (Employee & RoundHistory)[],
    type: string
  ) => {
    return employeeRounds.map((employee: Employee & RoundHistory) =>
      type === 'Completed' ? (
        <CompleteRow
          id={`Completed-Row-${employee.hcaid}`}
          columns={cols}
          size="large"
          onClick={() => openEmployeeProfile(employee)}
          key={employee.hcaid}
        >
          {formattedEmployeeRow(employee, type)}
        </CompleteRow>
      ) : (
        type === 'To Do' && (
          <ToDoRow
            id={`ToDo-Row-${employee.hcaid}`}
            data-testid={`ToDo-Row-${employee.hcaid}`}
            key={employee.hcaid}
            columns={cols}
            size="large"
            onClick={() => openEmployeeProfile(employee)}
          >
            {formattedEmployeeRow(employee, type)}
          </ToDoRow>
        )
      )
    );
  };
  const toDoRows = formatRows(sortedToDoValidationRounds, 'To Do');
  const completeRows = formatRows(sortedCompleteValidationRounds, 'Completed');

  const rows: (boolean | JSX.Element)[] = [...toDoRows, ...completeRows];

  return (
    <Table
      cols={cols}
      headers={headers}
      rows={rows}
      isTableEmpty={isTableEmpty}
      isTableEmptySearch={isTableSearchEmpty}
      customClass="validation-list-table"
    />
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  sasToken: state.ConfigReducer.sasToken,
  selectedDeptsFromStore: state.EmployeeReducer.selectedDepts,
  validationCompetencies: state.ConfigReducer.validValidationCompetencies
});

export default connect(mapReduxStateToProps)(ValidationListTable);
