import styled from 'styled-components';
import {
  NeuCard,
  NeuCardHeader,
  NeuInput,
  NeuLabel,
  NeuTextarea,
  NeuButton,
  NeuItem,
  NeuCardActions,
  NeuCardContent
} from '@neutron/react';

interface DropdownProps {
  showPopover: boolean;
}

export const AddQuestionCard = styled(NeuCard)`
  display: flex;
  flex-direction: column;
  background: #f6f6f6 !important;
  z-index: 10;
  width: 700px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-inline-end: 0;
  margin-top: 0;
`;

export const CardHeader = styled(NeuCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  --background: white;
`;

export const ModalCardContent = styled(NeuCardContent)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  max-width: 413px;
`;

export const InputHeading = styled(NeuLabel)`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 22px;
  width: 413px;
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #191919;
  flex-grow: 1;
`;

export const RadioLabel = styled(NeuLabel)`
  margin-right: 30px;
  margin-left: 3px;
`;

export const ModalInput = styled(NeuInput)`
  width: 97%;
  height: 42px;
`;

export const ModalTextArea = styled(NeuTextarea)`
  width: 97%;
  height: 88px;
  margin-bottom: 1.25rem;
`;

export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 97%;
  min-height: 27px;
  max-height: 160px;
  z-index: 10;
  position: absolute;
  top: 42px;
  background: white !important;
  opacity: 1;
  overflow-y: scroll;
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;

export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  padding: 1px;
  opacity: 1;
`;

export const CreateNewButton = styled(NeuButton)`
  margin-top: 0.875rem;
`;

export const ModalFooter = styled(NeuCardActions)`
  background: white !important;
  bottom: 0;
  height: 69px !important;
`;

export const QuestionPartCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 461px;
  height: 455px;
  margin-right: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin-bottom: 10;
`;

export const QuestionPartContents = styled.div`
  width: 620px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const QuestionPartHeader = styled(NeuLabel)`
  width: 136px;
  height: 26px;
  left: 16px;
  top: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #4b4b4b;
`;

export const StyledLabel = styled(NeuLabel)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`;

export const AddQuestionPartCard = styled.div`
  display: flex;
  position: fixed;
  width: 319px;
  height: 292px;
  left: calc(50% - 319px / 2);
  top: calc(50% - 292px / 2);
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  z-index: 10;
  padding-inline-start: 0;
`;
