import { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import AddIssueFromRound from '../shared/buttons/AddIssueFromRound';
import AddFbFromRoundBtn from '../shared/buttons/AddFeedbackFromRound';
import CancelRoundBtn from '../shared/buttons/CancelRound';
import CompleteRoundBtn from '../shared/buttons/CompleteRound';

import { StickyFooter } from './Round.styles';
import { Patient, Employee } from '../../config/interfaces';

const RoundFooter: FC<{
  isEdit: boolean;
  hasChanges: boolean;
  selectedPatient: Patient;
  selectedEmployee: Employee;
  unlistedPatientRoundParams?: any;
  type: string;
}> = ({
  isEdit,
  hasChanges,
  selectedPatient,
  selectedEmployee,
  unlistedPatientRoundParams,
  type
}) => {
  const [actualPatientData, setActualPatientData] = useState<any>();

  const hasAttachableTasks = useMemo(
    () => type === 'patient' || type === 'employee' || type === 'cned',
    [type]
  );

  useEffect(() => {
    const selectPatient = { ...selectedPatient };
    delete selectPatient.completed;
    if (selectedPatient) {
      setActualPatientData(selectPatient);
    } else {
      setActualPatientData(unlistedPatientRoundParams);
    }
  }, [selectedPatient, unlistedPatientRoundParams]);

  return (
    <StickyFooter id="Round-Footer">
      <div className="mr-8">
        <CompleteRoundBtn isEdit={isEdit} hasChanges={hasChanges} type={type} />
        <CancelRoundBtn isEdit={isEdit} type={type} />
      </div>
      {hasAttachableTasks && (
        <div>
          <AddIssueFromRound
            modalData={
              type === 'patient'
                ? actualPatientData
                : { ...selectedEmployee, department: selectedEmployee.dept }
            }
            type={type}
          />
          <AddFbFromRoundBtn
            modalData={
              type === 'patient' ? actualPatientData : selectedEmployee
            }
            type={type}
          />
        </div>
      )}
    </StickyFooter>
  );
};

const mapReduxStateToProps = (state: any) => {
  return {
    selectedPatient: state.UserReducer.selectedPatient,
    selectedEmployee: state.UserReducer.selectedEmployee,
    unlistedPatientRoundParams: state.UserReducer.unlistedPatientRoundParams
  };
};

export default connect(mapReduxStateToProps)(RoundFooter);
