import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { format, isYesterday, subDays } from 'date-fns';

import RoundHeader from './header';
import RoundFooter from './RoundFooter';
import Survey from './survey';
import CloseBtn from '../shared/buttons/CloseBtn';
import Loading from '../shared/Loading';

import {
  clearRoundState,
  setRoundStarted
} from '../../redux/actions/Round.action';
import {
  clearRoundSurvey,
  getRoundSurvey
} from '../../redux/actions/Template.action';

import { RootState } from '../../redux/store';

import { date } from '../../utils/helpers';

import {
  Employee,
  ITemplate,
  Patient,
  RoundAnswer
} from '../../config/interfaces';

import { FinishRoundTypography, RoundInfoContainer } from './Round.styles';

import finishedRoundIcon from '../../assets/images/complete_round_icon.png';

interface RoundProps {
  chosenSurvey: any;
  currentRound: any;
  fetchingRound: boolean;
  isRoundStarted: boolean;
  isRoundCompleted: boolean;
  loadingSurvey: boolean;
  masterTemplateList?: ITemplate[];
  selectedEmployee?: Employee;
  selectedPatient?: Patient;
  templateById: ITemplate;
  type: string;
  userId: string;
}

const supportMasterTemplateConfigIds = {
  csc: 'b4a91330-d7e7-11ed-b894-d16b58c37774',
  cned: 'c2a6d060-48fe-11ef-aeff-afe93ea5e8fd'
} as Record<any, string>;

const validationMasterTemplateConfigIds = {
  nlr: 'f5cfe160-e003-11ef-9728-eb71b7277d23' // needs to be updated
} as Record<any, string>;

const CompleteRoundView = () => (
  <div className="d-flex align-self-center align-items-center mt-9">
    <img src={finishedRoundIcon} alt="checkmark in circle with ribbon edge" />
    <FinishRoundTypography>You just completed a round!</FinishRoundTypography>
  </div>
);

const NoTemplateView = () => (
  <div className="d-flex align-self-center align-items-center mt-9">
    <FinishRoundTypography>No template found!</FinishRoundTypography>
  </div>
);

const Round: FC<RoundProps> = ({
  chosenSurvey,
  currentRound,
  fetchingRound,
  isRoundStarted,
  isRoundCompleted,
  loadingSurvey,
  masterTemplateList,
  selectedEmployee,
  selectedPatient,
  templateById,
  type,
  userId
}) => {
  const dispatch = useDispatch();

  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>();
  const [day, setDay] = useState<string>('today');
  const [selectedTime, setSelectedTime] = useState<string>(
    format(new Date(), 'HH:mm')
  );
  const [selectedDateTime, setSelectedDateTime] = useState<string>(
    date.convertToDateTime(new Date())
  );

  const prevTemplate = useMemo(() => chosenSurvey.templateConfigId, []);

  const createdByCurrentUser = useMemo(
    () =>
      currentRound &&
      Object.keys(currentRound).length > 0 &&
      currentRound.userId === userId,
    [currentRound, userId]
  );
  const isPast24Hrs = useMemo(
    () =>
      currentRound &&
      Object.keys(currentRound).length > 0 &&
      date.isOverOneDayOld(currentRound.origRoundDate),
    [currentRound]
  );

  const isNew = useMemo(() => !currentRound.lastEditDate, [currentRound]);
  const isEdit = useMemo(
    () => currentRound?.lastEditDate && !isPast24Hrs && createdByCurrentUser,
    [currentRound, createdByCurrentUser, isPast24Hrs]
  );
  const isViewOnly = useMemo(
    () => currentRound?.lastEditDate && (isPast24Hrs || !createdByCurrentUser),
    [currentRound, createdByCurrentUser, isPast24Hrs]
  );

  const isPastTemplateNotFound = useMemo(
    () => !isNew && !selectedTemplate,
    [isNew, selectedTemplate]
  );

  const adjustTime = useMemo(() => {
    const adjTime = new Date();
    // Patient round timestamp
    if (type === 'patient') {
      if (selectedTime) {
        adjTime.setHours(
          Number(selectedTime.split(':')[0]),
          Number(selectedTime.split(':')[1])
        );
      }
      if (day === 'yesterday')
        return date.convertDateToUTCTimestamp(subDays(adjTime, 1));
      return date.convertDateToUTCTimestamp(adjTime);
    }
    // Emp round timestamp
    const [year, month, rest] = selectedDateTime.split('-');
    if (month) {
      const [dayOfMonth, time] = rest.split('T');
      adjTime.setFullYear(Number(year), Number(month) - 1, Number(dayOfMonth));
      adjTime.setHours(Number(time.split(':')[0]), Number(time.split(':')[1]));
    }
    return date.convertDateToUTCTimestamp(adjTime);
  }, [selectedTime, selectedDateTime, day]);

  const masterTemplateOptions = useMemo(() => {
    if (type === 'patient') {
      return (
        masterTemplateList?.filter(
          template => template.roundingType === type
        ) || []
      );
    }
    return (
      masterTemplateList?.filter(template =>
        isNew
          ? template.roundingType === (type === 'csc' ? 'csrn' : type)
          : currentRound.roundingType &&
            template.roundingType === currentRound.roundingType
      ) || []
    );
  }, [currentRound, isNew, masterTemplateList, type]);

  const defaultTemplate = useMemo(() => {
    if (type === 'patient') {
      return prevTemplate
        ? masterTemplateOptions.find(
            temp => temp.templateConfigId === prevTemplate
          )
        : masterTemplateOptions[0];
    }
    if (type === 'employee') {
      // Employee rounds are defaulted based on direct report templates 30, 60, 90 or standard
      const today: any = new Date();

      // Check that effectiveStartDate exists and is not string null for vendors
      let selEmpStartDate;

      if (
        selectedEmployee?.effectiveStartDate &&
        selectedEmployee?.effectiveStartDate.includes('/')
      ) {
        const dateSplit = selectedEmployee?.effectiveStartDate.split('/');
        const formatToOrbitDate = dateSplit[2] + dateSplit[0] + dateSplit[1];
        selEmpStartDate = formatToOrbitDate;
      } else if (
        selectedEmployee?.effectiveStartDate &&
        selectedEmployee?.effectiveStartDate !== 'NULL'
      ) {
        selEmpStartDate = selectedEmployee?.effectiveStartDate;
      } else {
        selEmpStartDate = date.beginningOfYear();
      }

      const formattedStDt: any = selEmpStartDate?.slice(0, 9);
      const prettyStDt: any = date.datePrettier(formattedStDt);

      const date2: any = new Date(prettyStDt); // Employee Start Date effectiveStartDate prop

      const empDaysSinceHire = Math.abs(today - date2); // subtract start day from today
      const daysSinceHired = Math.ceil(
        empDaysSinceHire / (1000 * 60 * 60 * 24)
      ); // calc days

      let template: ITemplate[];
      if (daysSinceHired <= 31) {
        template = masterTemplateOptions.filter(temp =>
          temp.name.includes('30 Day')
        );
        return template[0];
      }
      if (daysSinceHired >= 32 && daysSinceHired <= 61) {
        template = masterTemplateOptions.filter(
          temp => temp.name === '60 Day Rounding Template'
        );
        return template[0];
      }
      if (daysSinceHired >= 61 && daysSinceHired <= 91) {
        template = masterTemplateOptions.filter(
          temp => temp.name === '90 Day Rounding Template'
        );
        return template[0];
      }
      if (daysSinceHired > 91 || !daysSinceHired) {
        template = masterTemplateOptions.filter(
          temp => temp.name === 'Standard Employee Rounding'
        );
        return template[0];
      }
      return undefined;
    }
    return masterTemplateOptions.find(
      temp =>
        temp.templateConfigId ===
        (type === 'validation'
          ? validationMasterTemplateConfigIds.nlr
          : supportMasterTemplateConfigIds[type])
    );
  }, [masterTemplateOptions, selectedEmployee, type]);

  const handleCloseClick = useCallback(() => {
    setDay('today');
    setSelectedTime(format(new Date(), 'HH:mm'));
    setSelectedDateTime(date.convertToDateTime(new Date()));
    dispatch(clearRoundState());
    if (
      type === 'employee' ||
      type === 'validation' ||
      type === 'csc' ||
      type === 'cned'
    )
      dispatch(clearRoundSurvey());
  }, [type]);

  const handleDateTimeChange = useCallback(
    (e: any) =>
      type === 'patient'
        ? setSelectedTime(e.detail.value)
        : setSelectedDateTime(e.detail.value),
    [type]
  );

  const handleTemplateSelect = useCallback((template: ITemplate) => {
    dispatch(clearRoundSurvey());
    setSelectedTemplate(template);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearRoundState());
      if (
        type === 'employee' ||
        type === 'validation' ||
        type === 'csc' ||
        type === 'cned'
      )
        dispatch(clearRoundSurvey());
    };
  }, []);

  useEffect(() => {
    if (isNew) setSelectedTemplate(defaultTemplate as ITemplate);
  }, [defaultTemplate, isNew]);

  useEffect(() => {
    if (!isNew)
      setSelectedTemplate(
        masterTemplateOptions.find(
          temp => temp.templateConfigId === currentRound.templateId
        )
      );
  }, [currentRound, isNew]);

  useEffect(() => {
    const foundCustomTemplateMaster = masterTemplateOptions.find(
      temp => temp.templateConfigId === templateById.templateConfigId
    );
    if (
      !isNew &&
      Object.keys(templateById).length > 0 &&
      foundCustomTemplateMaster
    )
      setSelectedTemplate(templateById);
  }, [isNew, masterTemplateOptions, templateById]);

  useEffect(() => {
    if (!fetchingRound && !isNew && !isRoundStarted && selectedTemplate)
      dispatch(setRoundStarted());
  }, [fetchingRound, isNew, isRoundStarted, selectedTemplate]);

  useEffect(() => {
    if (
      selectedTemplate &&
      Object.keys(selectedTemplate).length > 0 &&
      isRoundStarted
    ) {
      // TODO: Not loving the use of unlisted here for emp surveys as well
      if (userId && selectedPatient) {
        dispatch(
          getRoundSurvey({
            templateConfigId: selectedTemplate.templateConfigId,
            unlisted: false
          })
        );
      } else {
        dispatch(
          getRoundSurvey({
            templateConfigId: selectedTemplate.templateConfigId,
            unlisted: true
          })
        );
      }
    }
  }, [isRoundStarted, selectedTemplate]);

  useEffect(() => {
    if (
      currentRound &&
      currentRound.roundDate &&
      date.isOrbitTimestamp(currentRound.roundDate) &&
      type === 'patient'
    ) {
      setDay(
        isYesterday(date.parseDate(currentRound.roundDate))
          ? 'yesterday'
          : 'today'
      );
      setSelectedTime(format(date.parseDate(currentRound.roundDate), 'HH:mm'));
    } else if (
      currentRound &&
      currentRound.roundDate &&
      date.isOrbitTimestamp(currentRound.roundDate)
    ) {
      setSelectedDateTime(
        date.convertToDateTime(date.parseDate(currentRound.roundDate))
      );
    }
  }, [currentRound.roundDate]);

  const getCombinedAnswers = () => {
    return currentRound.answers?.reduce((acc: string, answer: RoundAnswer) => {
      let answerStr = '';
      if (answer.answer === null || answer.answer.length === 0) {
        return `${acc}#`; // we need a placeholder even if there is no answer
      }
      answer.answer.forEach(element => {
        answerStr += element.value;
      });
      return acc + answerStr;
    }, '');
  };

  const roundDate = useMemo(() => {
    return currentRound.roundDate;
  }, [currentRound.origRoundDate]);

  const originalAnswers = useMemo(() => {
    return getCombinedAnswers();
  }, [currentRound.origRoundDate]);

  const hasChanges = useMemo(() => {
    const currentAnswers = getCombinedAnswers();
    if (currentAnswers !== originalAnswers) {
      return true;
    }
    if (currentRound.roundDate === null || roundDate == null) {
      return false;
    }
    return currentRound.roundDate.slice(0, -2) !== roundDate.slice(0, -2);
  }, [currentRound.answers, currentRound.roundDate]);

  return (
    <div
      id="Round"
      className="d-flex flex-column w-100"
      style={{
        maxHeight: 'calc(100vh - 176px)',
        background: '#F6F6F6'
      }}
    >
      {isRoundCompleted ? (
        <>
          <RoundInfoContainer>
            <div className="pl-8">
              <CloseBtn
                id="CloseCompletedButton"
                text="Round"
                handleCloseClick={handleCloseClick}
              />
            </div>
          </RoundInfoContainer>
          <CompleteRoundView />
        </>
      ) : isPastTemplateNotFound ? (
        <>
          <RoundInfoContainer>
            <div className="pl-8">
              <CloseBtn
                id="CloseNotFoundButton"
                text="Round"
                handleCloseClick={handleCloseClick}
              />
            </div>
          </RoundInfoContainer>
          <NoTemplateView />
        </>
      ) : !fetchingRound ? (
        <>
          <RoundHeader
            day={day}
            hideCloseButton={isNew || !currentRound.id}
            isNew={isNew}
            isRoundStarted={isRoundStarted}
            isViewOnly={isViewOnly}
            masterTemplateOptions={masterTemplateOptions}
            roundBtnEnabled={Boolean(selectedTemplate)}
            selectedTemplate={selectedTemplate}
            selectedDateTime={selectedDateTime}
            selectedTime={selectedTime}
            type={type}
            handleCloseClick={handleCloseClick}
            handleDateTimeChange={handleDateTimeChange}
            handleTemplateSelect={handleTemplateSelect}
            setDay={setDay}
          />
          {isRoundStarted && (
            <>
              <Survey
                isNew={isNew}
                isViewOnly={isViewOnly}
                loading={loadingSurvey}
                selectedTemplate={selectedTemplate}
                selectedTimestamp={adjustTime}
                type={type}
              />
              {!isViewOnly &&
                !loadingSurvey &&
                Object.keys(chosenSurvey).length > 0 && (
                  <RoundFooter
                    isEdit={isEdit}
                    hasChanges={hasChanges}
                    type={type}
                  />
                )}
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  chosenSurvey: state.TemplateReducer.chosenSurvey,
  currentRound: state.RoundReducer.currentRound,
  fetchingRound: state.RoundReducer.fetchingRound,
  isRoundStarted: state.RoundReducer.isRoundStarted,
  isRoundCompleted: state.RoundReducer.isRoundCompleted,
  loadingSurvey: state.TemplateReducer.loading,
  masterTemplateList: state.TemplateReducer.masterTemplateList,
  templateById: state.TemplateReducer.templateById,
  userId: state.AuthorizedUser.authorizedUser.hcaid
});

export default connect(mapReduxStateToProps)(Round);
