import { FC } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { NeuHeader } from '@neutron/react';

import MainNav from './mainNav';
import SubNav from './subNav';
import SubNavPatientProfile from './subNavPatientProfile/SubNavPatientProfile';
import SubNavAccount from './subNavAccount/SubNavAccount';
import SubNavEmployeeProfile from './subNavEmployeeProfile/SubNavEmployeeProfile';
import { RootState } from '../../redux/store';
import { useFeatures } from '../../services/Features/features';
import { AuthUserProps } from '../../config/interfaces';

interface HeaderProps {
  facilityTitle: string;
  authorizedUser: AuthUserProps | undefined;
  divisionTitle: string;
}

const Header: FC<HeaderProps> = ({
  authorizedUser,
  facilityTitle,
  divisionTitle
}) => {
  const userName = authorizedUser?.firstName
    ? `${authorizedUser.firstName} ${authorizedUser.lastName}`
    : '';
  const { pathname } = useLocation();
  const { isCSCEnabled } = useFeatures();

  return (
    <NeuHeader id="Header">
      <MainNav userName={userName} />
      {!pathname.includes('/account') &&
        !pathname.includes('/profile') &&
        !(pathname.includes('/support') && !isCSCEnabled) && (
          <SubNav facilityTitle={facilityTitle} divisionTitle={divisionTitle} />
        )}
      {pathname.includes('/account') && <SubNavAccount />}
      {(pathname.includes('employees/profile') ||
        pathname.includes('validation/profile') ||
        pathname.includes('support/profile')) && <SubNavEmployeeProfile />}
      {pathname.includes('patients/profile') && <SubNavPatientProfile />}
    </NeuHeader>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser
  };
};

export default connect(mapReduxStateToProps)(Header);
