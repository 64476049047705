import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import CloseBtn from '../../shared/buttons/CloseBtn';
import RoundDateTimeSelect from './RoundDateTimeSelect';
import RoundTemplateSelect from './RoundTemplateSelect';
import StartNewRoundButton from './StartNewRoundButton';

import { setRoundStarted } from '../../../redux/actions/Round.action';

import { logRoundClick } from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';
import { date } from '../../../utils/helpers';

import { ITemplate } from '../../../config/interfaces';

import { CloseBtnContainer, RoundCloseContainer } from './Header.styles';

interface RoundHeaderProps {
  day: string;
  hideCloseButton?: boolean;
  isNew: boolean;
  isRoundStarted: boolean;
  isViewOnly: boolean;
  masterTemplateOptions: ITemplate[];
  roundBtnEnabled?: boolean;
  selectedTemplate?: ITemplate;
  selectedDateTime: string;
  selectedTime: string;
  type: string;
  handleCloseClick: () => void;
  handleDateTimeChange: (e: any) => void;
  handleTemplateSelect: (template: ITemplate) => void;
  setDay: Dispatch<SetStateAction<string>>;
}

const RoundHeader: FC<RoundHeaderProps> = ({
  day,
  hideCloseButton,
  isNew,
  isRoundStarted,
  isViewOnly,
  masterTemplateOptions,
  roundBtnEnabled,
  selectedTemplate,
  selectedDateTime,
  selectedTime,
  type,
  handleCloseClick,
  handleDateTimeChange,
  handleTemplateSelect,
  setDay
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [activeSearch, setActiveSearch] = useState<string>('');
  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounceValue(query, 250);

  const { logTrackingEvent } = useAnalyticsApi();

  const isInvalidTime = useMemo(
    () => day === 'today' && format(new Date(), 'HH:mm') < selectedTime,
    [selectedTime, day]
  );
  const isInvalidDateTime = useMemo(
    () => selectedDateTime > date.convertToDateTime(new Date()),
    [selectedDateTime]
  );

  const handleStartNewRoundClick = () => {
    logTrackingEvent(
      logRoundClick(
        'start',
        type === 'validation' || type === 'csc' || type === 'cned'
          ? type
          : undefined
      )
    );
    dispatch(setRoundStarted());
  };

  const handleTypeAheadChange = useCallback(
    (e: any) => setQuery(e.target.value),
    []
  );

  const searchResults = useMemo(() => {
    switch (activeSearch) {
      case 'round-template-select':
        return masterTemplateOptions.filter((template: ITemplate) =>
          template.name
            .toLowerCase()
            .includes(debouncedQuery.trim().toLowerCase())
        );
      default:
        return undefined;
    }
  }, [activeSearch, masterTemplateOptions, debouncedQuery]);

  return (
    <section style={{ minHeight: '128px' }}>
      <RoundCloseContainer className="d-flex justify-content-center">
        {!hideCloseButton && (
          <>
            <CloseBtnContainer className="pl-5">
              <CloseBtn
                id="CloseRoundButton"
                text={type === 'validation' ? 'Validation' : 'Round'}
                handleCloseClick={handleCloseClick}
              />
            </CloseBtnContainer>
            <div className="d-flex flex-row">
              <p style={{ fontStyle: 'italic', marginLeft: '5px' }}>
                You&apos;re viewing a previous round.
              </p>
            </div>
          </>
        )}
      </RoundCloseContainer>
      <div
        className="d-flex flex-column w-100 ml-5"
        style={{
          flex: 1
        }}
      >
        <div className="d-flex flex-row align-items-end ml-9">
          <RoundTemplateSelect
            id="round-template-select"
            closeOnClick
            disabled={
              !isNew ||
              isRoundStarted ||
              type === 'validation' ||
              type === 'csc' ||
              type === 'cned'
            }
            disabledWithTransparent={
              isNew &&
              (type === 'validation' || type === 'csc' || type === 'cned')
            }
            focused={activeSearch === 'round-template-select'}
            name="round-template-select"
            open={open}
            options={masterTemplateOptions}
            placeholder={selectedTemplate?.name || ''}
            query={query}
            searchResults={searchResults}
            styles={{ paddingTop: '3px' }}
            selectedTemplate={selectedTemplate}
            typeAhead
            handleTemplateSelect={handleTemplateSelect}
            setActiveSearch={setActiveSearch}
            setOpen={setOpen}
            setQuery={setQuery}
            setTypeAhead={setActiveSearch}
            setTypeAheadChange={handleTypeAheadChange}
          />
          <RoundDateTimeSelect
            day={day}
            isInvalidTime={isInvalidTime}
            isInvalidDateTime={isInvalidDateTime}
            isViewOnly={isViewOnly}
            selectedTime={selectedTime}
            selectedDateTime={selectedDateTime}
            type={type}
            handleDateTimeChange={handleDateTimeChange}
            setDay={setDay}
          />
        </div>
        {!isRoundStarted && isNew && (
          <StartNewRoundButton
            disabled={isInvalidTime || isInvalidDateTime || !roundBtnEnabled}
            handleStartNewRoundClick={handleStartNewRoundClick}
            type={type}
          />
        )}
      </div>
    </section>
  );
};

export default RoundHeader;
