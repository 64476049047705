import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuButton, NeuIcon, NeuLabel, NeuTextarea } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import {
  CardsContainer,
  DrawerContainer,
  HorizontalDivider,
  NotesContainer,
  NoteCardsContainer,
  SaveNoteButton,
  TaskCardContainer,
  TaskCardContainerForRound
} from './HistorySideDrawer.styles';
import NotesCard from '../sideDrawerCards/NotesCard';
import PastRoundCard from '../sideDrawerCards/PastRoundsCard/PastRoundCard';
import PastTasksCard from '../sideDrawerCards/PastTasksCard';

import { AuthUserProps, Task } from '../../config/interfaces';
import { postNote, removeNote } from '../../redux/actions/Patient.action';
import {
  openModal,
  setModalData,
  setModalType,
  setModalAction
} from '../../redux/actions/Modal.action';
import { getRound } from '../../redux/actions/Round.action';
import { selectDataForEdit } from '../../redux/actions/Task.action';
import { setToastType } from '../../redux/actions/Toast.action';
import {
  postEmployeeNote,
  removeEmployeeNote
} from '../../redux/actions/Employee.action';
import { getFacilities } from '../../redux/actions/User.action';
import { logRoundProfileClick } from '../../utils/analyticsHelpers';
import { date } from '../../utils/helpers';

import { toast } from '../../services/Toast';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';

interface HistorySideDrawerProps {
  authorizedUser?: AuthUserProps;
  facilities: any;
  selectedTab: string;
  selectedPerson?: any;
  roundHistory?: any;
  tasks?: Task[];
  type: string;
  supportType?: string;
  loadingAddEmployeeNotes: boolean;
  loadingAddPatientNotes: boolean;
  activePastRoundId?: string;
  serviceCentralCategories: string[];
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const HistorySideDrawer: FC<HistorySideDrawerProps> = ({
  authorizedUser,
  facilities,
  selectedTab,
  selectedPerson,
  roundHistory,
  tasks,
  type,
  supportType,
  loadingAddPatientNotes,
  loadingAddEmployeeNotes,
  activePastRoundId,
  serviceCentralCategories,
  setSelectedTab
}) => {
  const dispatch = useDispatch();
  const [noteMessage, setNoteMessage] = useState<string>('');
  const [pastIssues, setPastIssues] = useState<any>([]);
  const [pastFeedbacks, setPastFeedbacks] = useState<any>([]);

  const { logTrackingEvent } = useAnalyticsApi();
  const { isEnterpriseAdmin } = useUserRolesRef();

  const sortedRoundHistory = roundHistory.sort((a: any, b: any) =>
    a.roundDate < b.roundDate ? 1 : -1
  );

  const hasPastTasks = useMemo(
    () =>
      !(
        type === 'validation' ||
        (type === 'support' &&
          (supportType === 'csc' || supportType === 'validation'))
      ),
    [type, supportType]
  );

  useEffect(() => {
    dispatch(getFacilities());
  }, []);

  useEffect(() => {
    // filter out issues and feedback from tasks
    const issues = tasks
      ?.filter((task: any) => {
        return task.tasktype === 'issue' || task.taskType === 'issue';
      })
      .sort((a: any, b: any) => (a.taskDate < b.taskDate ? 1 : -1));
    const feedbacks = tasks
      ?.filter((task: any) => {
        return task.tasktype === 'feedback' || task.taskType === 'feedback';
      })
      .sort((a: any, b: any) => (a.taskDate < b.taskDate ? 1 : -1));

    setPastIssues(issues);
    setPastFeedbacks(feedbacks);
  }, [tasks]);

  useEffect(() => {
    // To clear note input
    if (loadingAddEmployeeNotes || loadingAddPatientNotes === false) {
      setNoteMessage('');
    }
  }, [loadingAddPatientNotes, loadingAddEmployeeNotes]);

  const handleNoteMessageChange = (e: any) => {
    setNoteMessage(e.target.value);
  };
  const saveNote = () => {
    if (type === 'patient' && noteMessage.trim()) {
      const postNotePayload = {
        author: `${authorizedUser?.firstName} ${authorizedUser?.lastName}`,
        facilityId: authorizedUser?.facilityId,
        accountNumber: selectedPerson?.accountNum,
        message: noteMessage
      };
      logTrackingEvent(logRoundProfileClick('save-notes'));
      dispatch(postNote(postNotePayload));
    }
    if (
      (type === 'employee' || type === 'validation' || type === 'support') &&
      noteMessage.trim()
    ) {
      const postNotePayload = {
        userId: authorizedUser?.hcaid,
        employeeId: selectedPerson?.hcaid,
        author: `${authorizedUser?.firstName} ${authorizedUser?.lastName}`,
        message: noteMessage
      };
      logTrackingEvent(logRoundProfileClick('save-notes', supportType));
      dispatch(postEmployeeNote(postNotePayload));
    }
    setNoteMessage('');
  };

  const deleteNote = (noteId: string) => {
    dispatch(setToastType({ type: 'Delete Note' }));
    if (type === 'patient') {
      const postNotePayload = {
        facilityId: authorizedUser?.facilityId,
        accountNumber: selectedPerson?.accountNum,
        noteId
      };
      toast(
        'Are you sure you would like to delete this note?',
        'info',
        null,
        500,
        true,
        null,
        () => dispatch(removeNote(postNotePayload))
      );
    }
    if (type === 'employee' || type === 'validation' || type === 'support') {
      const postNotePayload = {
        userId: authorizedUser?.hcaid,
        employeeId: selectedPerson?.hcaid,
        noteId
      };
      toast(
        'Are you sure you would like to delete this note?',
        'info',
        null,
        500,
        true,
        null,
        () => dispatch(removeEmployeeNote(postNotePayload))
      );
    }
  };

  const feedbackTitle = (taskCategory: []) => {
    const extraItems = taskCategory.length - 2;
    let title;

    if (taskCategory.length > 2) {
      title = taskCategory
        .slice(0, 2)
        .toString()
        .concat(' (+', extraItems.toString(), ')');
    } else {
      title = taskCategory.toString();
    }
    return title;
  };

  const handleEditIssue = (issue: any) => {
    if (
      serviceCentralCategories.includes(issue.taskSubtype) ||
      serviceCentralCategories.includes(issue.category)
    ) {
      dispatch(setModalType('View Issue'));
    } else if (
      isEnterpriseAdmin ||
      (issue.userId.toLowerCase() === authorizedUser?.hcaid.toLowerCase() &&
        !issue.completed &&
        issue.taskDate &&
        !date.isOverOneDayOld(issue.taskDate))
    ) {
      dispatch(setModalType('Edit Issue'));
    } else {
      dispatch(setModalType('View Issue'));
    }
    dispatch(setModalData(issue));
    dispatch(openModal());
  };

  const handleEditRound = (round: any) => {
    if (round.roundId)
      dispatch(getRound({ roundId: round.roundId, fromHistorydrawer: true }));
  };

  const handleEditFeedback = (feedback: any) => {
    dispatch(setModalType('Feedback'));
    dispatch(selectDataForEdit(feedback));

    // Edit modal
    if (
      !feedback.completed &&
      (isEnterpriseAdmin ||
        (feedback.userId.toLowerCase() ===
          authorizedUser?.hcaid.toLowerCase() &&
          feedback.taskDate &&
          !date.isOverOneDayOld(feedback.taskDate)))
    ) {
      dispatch(setModalAction('EDIT'));
      dispatch(openModal());
      // Partial edit modal
    } else if (
      !feedback.completed &&
      feedback.userId.toLowerCase() === authorizedUser?.hcaid.toLowerCase() &&
      feedback.taskDate &&
      date.isOverOneDayOld(feedback.taskDate)
    ) {
      dispatch(setModalAction('PARTIAL-EDIT'));
      dispatch(openModal());
    } else {
      // View modal
      dispatch(setModalAction('VIEW'));
      dispatch(openModal());
    }
  };

  const handleClose = () => {
    setSelectedTab('');
  };

  const renderDrawerCard = () => {
    if (selectedTab === 'notes' && selectedPerson?.notes) {
      return (
        <NotesContainer className="p-3">
          <div>
            <NeuLabel id="Write-A-Note">Write a note</NeuLabel>
            <NeuTextarea
              id="Note-Text-Area"
              placeholder="Do not include PHI"
              inputmode="search"
              enterkeyhint="search"
              spellcheck
              value={noteMessage}
              onNeuChange={handleNoteMessageChange}
            />
            <SaveNoteButton
              id="Save-Note"
              color="primary"
              fill="raised"
              size="small"
              onClick={() => saveNote()}
              disabled={!noteMessage.trim() ? true : undefined}
            >
              Save Note
            </SaveNoteButton>
          </div>
          <HorizontalDivider />

          <NoteCardsContainer>
            {selectedPerson?.notes
              ?.sort((a: any, b: any) => (a.timestamp < b.timestamp ? 1 : -1))
              .map((note: any) => {
                let formattedDate;
                if (note.timestamp?.includes('T')) {
                  const dateSplit = note.timestamp?.split('T')[0].split('-');
                  formattedDate = `${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`;
                } else {
                  formattedDate = `${note.timestamp.slice(
                    4,
                    6
                  )}/${note.timestamp.slice(6, 8)}/${note.timestamp.slice(
                    0,
                    4
                  )}`;
                }
                return (
                  <div id={`Notes-Card-${note.noteId}`} key={note.noteId}>
                    <NotesCard
                      key={note.noteId}
                      noteId={note.noteId}
                      date={formattedDate || ''}
                      author={note.author}
                      message={note.message}
                      deleteNote={() => deleteNote(note.noteId)}
                    />
                  </div>
                );
              })}
          </NoteCardsContainer>
        </NotesContainer>
      );
    }

    if (selectedTab === 'pastRounds') {
      return (
        <CardsContainer>
          {sortedRoundHistory?.map((round: any) => {
            let roundDateCorrected = round.roundDate;
            if (roundDateCorrected.length < 14) {
              roundDateCorrected = roundDateCorrected.padEnd(14, '0');
            }
            const formattedRoundDate =
              date.dateTimeCombination(roundDateCorrected);
            const facilityObj = facilities?.find(
              (facility: any) => facility.facilityId === round.facilityId
            );

            return (
              <TaskCardContainerForRound
                onClick={() => handleEditRound(round)}
                onKeyDown={() => handleEditRound(round)}
                aria-hidden
                key={round.roundId}
                activeColor={`${
                  round.roundId === activePastRoundId ? '#EFF8FD' : ''
                }`}
              >
                <PastRoundCard
                  key={round.roundId}
                  roundId={round.roundId}
                  dateTime={
                    formattedRoundDate === 'Unknown'
                      ? formattedRoundDate
                      : formattedRoundDate.replace(', ', ' @ ').toLowerCase()
                  }
                  name={`${round.userFirstName} ${round.userLastName}`}
                  userId={round.userId}
                  facility={facilityObj?.facility}
                  room={round.room}
                  unitId={round.unitId}
                  template={
                    type === 'employee' ||
                    type === 'validation' ||
                    type === 'support'
                      ? round.templateName || 'Unknown'
                      : undefined
                  }
                />
              </TaskCardContainerForRound>
            );
          })}
        </CardsContainer>
      );
    }
    if (selectedTab === 'pastIssues' && hasPastTasks) {
      return (
        <CardsContainer className="p-3">
          {pastIssues?.map((issue: any) => {
            return (
              <TaskCardContainer
                key={issue.taskId}
                onClick={() => handleEditIssue(issue)}
                onKeyDown={() => handleEditIssue(issue)}
                aria-hidden
              >
                <PastTasksCard
                  key={issue.taskId}
                  taskId={issue.taskId}
                  subtype={issue.category || issue.taskSubtype}
                  taskDate={
                    issue.taskDate
                      ? date
                          .dayOfYr(issue.taskDate)
                          .replace(', ', ' @ ')
                          .toLowerCase()
                      : 'Unknown'
                  }
                  // TODO: should we remove this altogether?
                  // task title deprecated after release on 07/26/2023 user story 22529
                  taskTitle={issue.taskDate < '20230726' ? issue.title : ''}
                  taskDescription={issue.description}
                  completed={issue.completed}
                  facility={issue.facility}
                />
              </TaskCardContainer>
            );
          })}
        </CardsContainer>
      );
    }

    if (selectedTab === 'pastFeedback' && hasPastTasks) {
      return (
        <CardsContainer className="p-3">
          {pastFeedbacks?.map((feedback: any) => {
            return (
              <TaskCardContainer
                key={feedback.taskId}
                onClick={() => handleEditFeedback(feedback)}
                onKeyDown={() => handleEditFeedback(feedback)}
                aria-hidden
              >
                <PastTasksCard
                  key={feedback.taskId}
                  taskId={feedback.taskId}
                  subtype={
                    feedback.category || feedbackTitle(feedback.taskCategory)
                  }
                  taskDate={
                    feedback.taskDate
                      ? date
                          .dayOfYr(feedback.taskDate)
                          .replace(', ', ' @ ')
                          .toLowerCase()
                      : 'Unknown'
                  }
                  taskTitle={feedback.title}
                  taskDescription={feedback.description}
                  completed={feedback.completed}
                  facility={feedback.facility}
                />
              </TaskCardContainer>
            );
          })}
        </CardsContainer>
      );
    }
    return false; // for consistent return
  };

  return selectedTab ? (
    <DrawerContainer>
      <span
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
          borderBottom: '1px solid #b0b0b0'
        }}
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex={0}
      >
        <NeuButton color="primary" fill="flat-white">
          <NeuIcon small className="mr-2">
            first_page
          </NeuIcon>
          Hide Panel
        </NeuButton>
      </span>
      {renderDrawerCard()}
    </DrawerContainer>
  ) : null;
};

const mapReduxStateToProps = (state: any) => {
  return {
    activePastRoundId: state.RoundReducer.currentRound.roundId,
    authorizedUser: state.AuthorizedUser.authorizedUser,
    facilities: state.ConfigReducer.facilities,
    loadingAddEmployeeNotes: state.EmployeeReducer.loadingRemoveNotes,
    loadingAddPatientNotes: state.PatientReducer.loadingPostNotes,
    serviceCentralCategories: state.ConfigReducer.serviceCentralCategories
  };
};
export default connect(mapReduxStateToProps)(HistorySideDrawer);
