import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuButton } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { RootState } from '../../../../redux/store';
import { clearRoundState } from '../../../../redux/actions/Round.action';
import { clearRoundSurvey } from '../../../../redux/actions/Template.action';
import { logRoundClick } from '../../../../utils/analyticsHelpers';

interface CancelRoundBtnProps {
  isEdit: boolean;
  type: string;
}

const CancelRoundBtn: FC<CancelRoundBtnProps> = ({ isEdit, type }) => {
  const dispatch = useDispatch();

  const { logTrackingEvent } = useAnalyticsApi();

  const handleCancelRound = () => {
    logTrackingEvent(logRoundClick('cancel'));
    dispatch(clearRoundState());
    if (
      type === 'employee' ||
      type === 'validation' ||
      type === 'csc' ||
      type === 'cned'
    )
      dispatch(clearRoundSurvey());
  };

  return (
    <NeuButton
      id="Cancel-Round-Button"
      className={`mx-2${isEdit ? ' invisible' : ''}`}
      color="red-60"
      fill="raised"
      onClick={handleCancelRound}
    >
      Cancel {type === 'validation' ? 'Validation' : 'Round'}
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser
});

export default connect(mapReduxStateToProps)(CancelRoundBtn);
