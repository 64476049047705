import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { format, parse } from 'date-fns';

import { NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import {
  HeaderTitle,
  UserInfo,
  ProfileContainer,
  SubToolbar,
  HeaderTitleLarge
} from './SubNavEmployeeProfile.styles';
import { EmployeeAvatar } from '../../customTables/employeesTable/EmployeesTable.styles';
import { RootState } from '../../../redux/store';

import { logTableStart } from '../../../utils/analyticsHelpers';
import {
  handleBlobPicUrl,
  userProfilePicUrl,
  yearsOfService
} from '../../../utils/helpers';
import { Employee } from '../../../config/interfaces';
import defaultImage from '../../../assets/images/defaultImage.png';

interface ISubNavEmployeeProfileProps {
  sasToken: string;
  employeeDetails?: Employee;
  searchedEmployees: Employee[];
}

const SubNavEmployeeProfile: FC<ISubNavEmployeeProfileProps> = ({
  sasToken,
  employeeDetails,
  searchedEmployees
}) => {
  const [anniversary, setAnniversary] = useState<string>('');
  const [email, setEmail] = useState<string>(employeeDetails?.email || '');
  const [effectiveStartDate, setEffectiveStartDate] = useState<string>('');
  const [rnExperienceDate, setRnExperienceDate] = useState<string>('');

  const { logTrackingEvent } = useAnalyticsApi();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchedEmployees[0]) {
      if (!employeeDetails?.email) setEmail(searchedEmployees[0].email);
      if (searchedEmployees[0].effectiveStartDate.includes('/')) {
        setEffectiveStartDate(searchedEmployees[0].effectiveStartDate);
      } else if (
        searchedEmployees[0].effectiveStartDate &&
        searchedEmployees[0].effectiveStartDate !== 'NULL' &&
        !searchedEmployees[0].effectiveStartDate.includes('/')
      ) {
        setEffectiveStartDate(
          format(
            parse(
              searchedEmployees[0].effectiveStartDate,
              'yyyyMMdd',
              new Date()
            ),
            'MM/dd/yyyy'
          )
        );

        if (searchedEmployees[0].annivDate) {
          setAnniversary(searchedEmployees[0].annivDate);
        } else setAnniversary('N/A');
      } else if (
        searchedEmployees[0].startDate &&
        searchedEmployees[0].startDate !== 'NULL'
      ) {
        setEffectiveStartDate(searchedEmployees[0].startDate);
      } else setEffectiveStartDate('N/A');
      if (
        searchedEmployees[0].annivDate &&
        searchedEmployees[0].annivDate !== 'NULL'
      ) {
        setAnniversary(searchedEmployees[0].annivDate);
      } else setAnniversary('N/A');
      if (searchedEmployees[0].rnExperienceDate)
        setRnExperienceDate(searchedEmployees[0].rnExperienceDate);
    }
  }, [searchedEmployees]);

  const profileType = useMemo(
    () =>
      pathname.includes('/employees')
        ? 'employee'
        : pathname.includes('/validation')
        ? 'validation'
        : 'support',
    [pathname]
  );

  const goback = () => {
    logTrackingEvent(
      logTableStart(
        profileType === 'employee'
          ? '/employees/list'
          : profileType === 'validation'
          ? '/validation/list'
          : pathname.includes('/csc')
          ? '/support/csc/list'
          : '/support/cned/list'
      )
    );
    navigate(-1);
  };
  return (
    <SubToolbar id="SubNav-Employee">
      <span
        onClick={goback}
        onKeyDown={goback}
        role="button"
        tabIndex={0}
        style={{
          marginTop: 10,
          marginLeft: 10,
          position: 'absolute',
          display: 'flex',
          flex: '1',
          flexDirection: 'row',
          cursor: 'pointer'
        }}
      >
        <NeuIcon feedback="default" color="primary" left large>
          chevron_left
        </NeuIcon>
        <span
          id="Back-To-Employee-List"
          style={{
            color: '#2075AD',
            fontSize: 14,
            marginTop: 4
          }}
        >
          Go Back
        </span>
      </span>

      <ProfileContainer>
        <HeaderTitleLarge style={{ display: 'flex', flexDirection: 'row' }}>
          {/* <NeuAvatar
            id="SubNav-Employee-User-Image"
            slot="avatar"
            color="gray-60"
            style={{ marginRight: 10, marginTop: 5 }}
          > */}
          {sasToken && employeeDetails ? (
            <EmployeeAvatar
              src={
                handleBlobPicUrl(employeeDetails.pictureUrl, sasToken) ??
                userProfilePicUrl(employeeDetails?.hcaid, sasToken)
              }
              alt="User Image"
              onError={(e: any) => {
                e.currentTarget.src = defaultImage;
                return null;
              }}
              style={{ marginRight: 10, marginTop: 5 }}
            />
          ) : (
            <EmployeeAvatar src={defaultImage} alt="Default Image" />
          )}
          {/* </NeuAvatar> */}
          <HeaderTitle id="SubNav-Employee-User-Name-34">
            Name & 3-4 ID
            <UserInfo>
              {employeeDetails?.firstName} {employeeDetails?.lastName} |{' '}
              {employeeDetails?.hcaid}
            </UserInfo>
          </HeaderTitle>
        </HeaderTitleLarge>
        <HeaderTitleLarge id="SubNav-Employee-User-Email">
          Email
          <UserInfo>{email}</UserInfo>
        </HeaderTitleLarge>
        <HeaderTitle id="SubNav-Employee-User-Anniversary">
          Anniversary
          <UserInfo>{anniversary}</UserInfo>
        </HeaderTitle>
        <HeaderTitle id="SubNav-Employee-User-Dept-Start">
          Dept Start Date
          <UserInfo>{effectiveStartDate}</UserInfo>
        </HeaderTitle>
        {searchedEmployees[0]?.title !== 'LPN' && (
          <HeaderTitle id="SubNav-Employee-User-Rn-Experience">
            RN Experience
            <UserInfo>
              {rnExperienceDate ? yearsOfService(rnExperienceDate) : 'Unknown'}
            </UserInfo>
          </HeaderTitle>
        )}
      </ProfileContainer>
    </SubToolbar>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  employeeDetails: state.UserReducer.selectedEmployee,
  searchedEmployees: state.EmployeeReducer.searchedEmployees,
  sasToken: state.ConfigReducer.sasToken
});

export default connect(mapReduxStateToProps)(SubNavEmployeeProfile);
