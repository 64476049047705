import { FC } from 'react';

import { NeuButton } from '@neutron/react';

interface StartNewRoundBtnProps {
  disabled: boolean;
  handleStartNewRoundClick?: () => void;
  type: string;
}

const StartNewRoundButton: FC<StartNewRoundBtnProps> = ({
  disabled,
  handleStartNewRoundClick,
  type
}) => {
  return (
    <NeuButton
      className="mt-2 ml-9"
      disabled={disabled}
      style={{ width: 178, height: 40 }}
      onClick={disabled ? undefined : handleStartNewRoundClick}
    >
      Start a New {type === 'validation' ? 'Validation' : 'Round'}
    </NeuButton>
  );
};

export default StartNewRoundButton;
