import { connect, useDispatch } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { NeuAvatar } from '@neutron/react';

import Question from './question';
import Loading from '../../shared/Loading';
import { SurveyContainer } from './Survey.styles';

import { createAnyId, date } from '../../../utils/helpers';
import { useDebounceValue } from '../../../utils/debouncers';

import { RootState } from '../../../redux/store';
import { setCurrentRound } from '../../../redux/actions/Round.action';

import {
  AuthUserProps,
  Employee,
  ITemplate,
  Patient
} from '../../../config/interfaces';

interface ISurveyProps {
  chosenSurvey: any;
  isNew: boolean;
  isViewOnly: boolean;
  loading: boolean;
  round: any;
  selectedPatient: Patient;
  selectedEmployee: Employee;
  selectedTemplate?: ITemplate;
  selectedTimestamp: string;
  type: string;
  unListedPatientParams: any;
  user: AuthUserProps;
}

const Survey: FC<ISurveyProps> = ({
  chosenSurvey,
  isNew,
  isViewOnly,
  round,
  loading,
  selectedPatient,
  selectedEmployee,
  selectedTemplate,
  selectedTimestamp,
  unListedPatientParams,
  type,
  user
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});

  const debouncedData = useDebounceValue(data, 400);

  useEffect(() => {
    setData({});
    if (Object.keys(chosenSurvey).length > 0) {
      if (!isNew) {
        setData({
          ...chosenSurvey,
          selectedConditions:
            round.answers?.reduce(
              (conds: string[], ans: any) =>
                ans.answer?.map((a: any) => a.flag).filter((f: string) => f)
                  .length > 0
                  ? [
                      ...conds,
                      {
                        questionId: ans.questionId,
                        flags:
                          ans.answer
                            ?.map((a: any) => a.flag)
                            .filter((f: string) => f) || []
                      }
                    ]
                  : [...conds],
              []
            ) || [],
          questions: chosenSurvey.questions.map((ques: any) => {
            const answerFilter =
              round.answers?.filter((ans: any) => {
                return ans.questionId === ques.questionId;
              }) || [];
            ques.questionParts?.forEach((part: any) => {
              answerFilter.forEach((answerPart: any) => {
                if (part.questionPartId === answerPart.questionPartId) {
                  if (answerPart.answer)
                    part.selectedAnswer = answerPart.answer; // eslint-disable-line no-param-reassign
                }
              });
            });
            return {
              ...ques,
              conditions:
                answerFilter?.reduce(
                  (acc: any[], ans: any) =>
                    ans.flag
                      ? [
                          ...acc,
                          {
                            questionPartId: ans.questionPartId,
                            flag: ans.answer.reduce(
                              (flags: string[], a: any) => [...flags, a.flag],
                              []
                            )
                          }
                        ]
                      : [...acc],
                  []
                ) || []
            };
          })
        });
      } else {
        setData({
          ...chosenSurvey,
          selectedConditions: [],
          questions: chosenSurvey.questions.map((ques: any) => ({
            ...ques,
            conditions: []
          }))
        });
      }
    }
    if (type === 'patient' && isNew) {
      if (selectedPatient) {
        dispatch(
          setCurrentRound({
            accountNum: selectedPatient.accountNum,
            bed: selectedPatient.bed,
            _source: 'web',
            dept: user.dept,
            division: user.division,
            facilityId: user.facilityId,
            origRoundDate: date.convertDateToUTCTimestamp(new Date()),
            roundDate: selectedTimestamp,
            patientFirstName: selectedPatient.firstName,
            patientId: selectedPatient.urn,
            patientLastName: selectedPatient.lastName,
            patientMRN: selectedPatient.mrn,
            room: selectedPatient.room,
            roundId: createAnyId('round', user.facilityId),
            roundingType: 'patient',
            taskIds: [],
            templateId: selectedTemplate?.templateId,
            templateConfigId: selectedTemplate?.templateConfigId,
            templateName: selectedTemplate?.name,
            title: user.title,
            unit: '', // need to add the unit name here
            unitId: selectedPatient.unitId,
            userFirstName: user.firstName,
            userId: user.hcaid,
            userLastName: user.lastName,
            answers: []
          })
        );
      } else {
        dispatch(
          setCurrentRound({
            accountNum: unListedPatientParams.mrn,
            bed: unListedPatientParams.bed,
            _source: 'web',
            dept: user.dept,
            division: user.division,
            facilityId: user.facilityId,
            origRoundDate: date.convertDateToUTCTimestamp(new Date()),
            roundDate: selectedTimestamp,
            patientFirstName: unListedPatientParams.firstName,
            patientId: unListedPatientParams.mrn,
            patientLastName: unListedPatientParams.lastName,
            patientMRN: unListedPatientParams.mrn,
            room: unListedPatientParams.room,
            roundId: createAnyId('round', user.facilityId),
            roundingType: 'patient',
            taskIds: [],
            templateId: selectedTemplate?.templateId,
            templateConfigId: selectedTemplate?.templateConfigId,
            templateName: selectedTemplate?.name,
            title: user.title,
            unit: '', // need to add the unit name here
            unitId: unListedPatientParams.unitId,
            userFirstName: user.firstName,
            userId: user.hcaid,
            userLastName: user.lastName,
            answers: []
          })
        );
      }
    }
    if (
      (type === 'employee' ||
        type === 'validation' ||
        type === 'csc' ||
        type === 'cned') &&
      isNew
    ) {
      dispatch(
        setCurrentRound({
          dept: user.dept,
          division: selectedEmployee.division,
          employeeDept: selectedEmployee.dept,
          employeeFirstName: selectedEmployee.firstName,
          employeeId: selectedEmployee.hcaid,
          employeeLastName: selectedEmployee.lastName,
          employeeTitle: selectedEmployee.title,
          facilityId: user.facilityId,
          origRoundDate: date.convertDateToUTCTimestamp(new Date()),
          roundDate: selectedTimestamp,
          roundId: createAnyId('round', user.facilityId),
          roundingType: type === 'csc' ? 'csrn' : type,
          taskIds: [],
          templateId: selectedTemplate?.templateId,
          templateConfigId: selectedTemplate?.templateConfigId,
          templateName: selectedTemplate?.name,
          title: user.title,
          userFirstName: user.firstName,
          userId: user.hcaid,
          userLastName: user.lastName,
          _source: 'web',
          answers: []
        })
      );
    }
  }, [chosenSurvey]);

  useEffect(() => {
    if (!isViewOnly) {
      dispatch(
        setCurrentRound({
          ...round,
          roundDate: selectedTimestamp
        })
      );
    }
  }, [selectedTimestamp]);

  useEffect(() => {
    if (Object.keys(debouncedData).length > 0) {
      dispatch(
        setCurrentRound({
          ...round,
          answers: isViewOnly
            ? round.answers || []
            : debouncedData.questions.reduce((acc: any[], q: any) => {
                return acc.concat(
                  q.questionParts.map((questionPart: any) => ({
                    question: questionPart.question,
                    questionId: q.questionId,
                    questionPartId: questionPart.questionPartId,
                    answer:
                      questionPart.selectedAnswer &&
                      questionPart.selectedAnswer.length > 0
                        ? questionPart.selectedAnswer.length > 1 ||
                          (questionPart.selectedAnswer.length === 1 &&
                            questionPart.selectedAnswer[0].value.trim())
                          ? questionPart.selectedAnswer
                          : []
                        : []
                  }))
                );
              }, []),

          completed:
            type === 'patient'
              ? debouncedData?.selectedConditions?.some(
                  (cond: { questionId: string; flags: string[] }) =>
                    cond.flags.indexOf('Able Round') !== -1
                )
              : true
        })
      );
    }
  }, [debouncedData]);

  return (
    <SurveyContainer id="Round-Survey" className="w-100" isLoading={loading}>
      {loading && <Loading />}
      {!loading &&
        data.questions?.length > 0 &&
        data.questions
          // .filter(
          //   (q: any) =>
          //     q.questionCondition.some((condition: string) =>
          //       data.selectedConditions.some(
          //         (c: { questionId: string; flags: string[] }) =>
          //           c.flags.indexOf(condition) !== -1
          //       )
          //     ) || !q.questionCondition.length
          // ) // Commented for now.
          .filter(
            (q: any) =>
              (isViewOnly && data.selectedConditions.length === 0) ||
              q.questionCondition?.length === 0 ||
              (data.selectedConditions &&
                data.selectedConditions.length > 0 &&
                q.questionCondition?.some(
                  (condition: string) =>
                    data.selectedConditions.findIndex(
                      (c: { questionId: string; flags: string[] }) =>
                        c.flags.indexOf(condition) !== -1
                    ) !== -1
                ))
          )
          .map((q: any, i: number) => (
            <div key={q.questionId} style={{ display: 'flex' }}>
              <NeuAvatar
                className="mt-8"
                color="gray-10"
                initials={`${i + 1}`}
              />
              <Question
                data={data}
                q={q}
                setData={setData}
                viewOnly={isViewOnly}
              />
            </div>
          ))}
    </SurveyContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  user: state.AuthorizedUser.authorizedUser,
  round: state.RoundReducer.currentRound,
  chosenSurvey: state.TemplateReducer.chosenSurvey,
  selectedPatient: state.UserReducer.selectedPatient,
  selectedEmployee: state.UserReducer.selectedEmployee,
  unListedPatientParams: state.UserReducer.unlistedPatientRoundParams
});

export default connect(mapReduxStateToProps)(Survey);
